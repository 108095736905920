import React from "react"
import { Link, graphql } from "gatsby"
import classnames from "classnames"

import Layout from "../components/layout"
import SEO from "../components/seo"

import blogPostDate from "../helpers/formatDate"
import getCategoriesById from "../helpers/getCategoriesById"

import styles from "../scss/blog.module.scss"

function PostList({ data, pageContext }) {
  const posts = data.allMdx.edges
  const title = "Bildungsblog"
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/bildungsblog" : `/bildungsblog/${(currentPage - 1).toString()}`
  const nextPage = `/bildungsblog/${(currentPage + 1).toString()}`
  const pageTitle =
    currentPage === 1 ? title : `${title} – Seite ${currentPage}`

  return (
    <Layout title={title} titleClassName={styles.blog__headling}>
      <SEO title={pageTitle} />

      <ul className={styles.blog__list}>
        {posts.map(post => {
          if (!post) return null

          const { slug } = post.node.fields
          const { date, title, categories } = post.node.frontmatter
          const sluggedCategories = getCategoriesById(categories)

          return (
            <li key={date}>
              <article className={styles.blog__teaser}>
                <h2 className="h5">
                  <Link to={slug}>
                    <time dateTime={date}>{title}</time>
                  </Link>
                </h2>
                <div className={styles.blog__meta}>
                  <div className={styles.blog__metaItem}>
                    {blogPostDate(date)}
                  </div>
                  <div className={styles.blog__metaItem}>
                    {sluggedCategories.map((link, index) => (
                      <React.Fragment key={link.name}>
                        <Link to={link.slug}>{link.name}</Link>
                        {sluggedCategories.length - 1 !== index ? ", " : ""}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                {post && post.node && post.node.excerpt && (
                  <p>{post.node.excerpt}</p>
                )}
                <p>
                  <Link to={slug}>Artikel lesen</Link>
                </p>
              </article>
            </li>
          )
        })}
      </ul>

      {numPages && numPages > 1 && (
        <nav aria-label="Pager für die Blogartikelliste">
          <ul className="pagination justify-content-center mt-5">
            <li
              className={classnames({
                "page-item": true,
                disabled: isFirst,
              })}
            >
              {isFirst && <span className="page-link">Vorherige</span>}
              {!isFirst && (
                <Link to={prevPage} rel="prev" className="page-link">
                  Vorherige
                </Link>
              )}
            </li>
            {Array.from({ length: numPages }, (_, i) => {
              return (
                <li
                  key={`pagination-number${i + 1}`}
                  className={classnames({
                    "page-item": true,
                    active: currentPage === i + 1,
                  })}
                >
                  <Link
                    to={`/bildungsblog/${i === 0 ? "" : i + 1}`}
                    className="page-link"
                  >
                    {i + 1}
                  </Link>
                </li>
              )
            })}
            <li
              className={classnames({
                "page-item": true,
                disabled: isLast,
              })}
            >
              {isLast && <span className="page-link">Nächste</span>}
              {!isLast && (
                <Link to={nextPage} rel="next" className="page-link">
                  Nächste
                </Link>
              )}
            </li>
          </ul>
        </nav>
      )}
    </Layout>
  )
}

export const query = graphql`
  query blogPosts($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { slug: { regex: "/^/bildungsblog//" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            title
            categories
          }
          excerpt(pruneLength: 200)
        }
      }
    }
  }
`

export default PostList
